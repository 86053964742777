import styled from "styled-components";

import { LINHA_BRANCA } from "../../assets/images";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #86bb73;
  height: 100vh;
  align-items: center;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);

  h1 {
    color: #fff;
    text-shadow: 1px 2px rgb(0 0 0 / 30%);

    @media (max-height: 1024px) {
      font-size: 2rem;
    }
  }

  .container {
    height: 100%;
    background-color: #86bb73;
    padding-top: 25px;
    @media (max-height: 1024px) {
      padding-top: 10px;
    }
  }

  .white-line-left {
    background-image: url(${LINHA_BRANCA});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center right;
    height: 100%;
  }

  .white-line-right {
    background-image: url(${LINHA_BRANCA});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center left;
    height: 100%;
    transform: scaleX(-1);
  }

  span.news-title {
    font-family: "GlacialIndifferenceRegular";
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 1em;
    text-align: center;
    font-weight: 600;
    width: 100%;

    @media (max-height: 1024px) {
      font-size: 0.9em;
    }
  }

  span.news-desc {
    padding-top: 5px;
    color: #000;
    font-family: "GlacialIndifferenceRegular";
    letter-spacing: 0.1em;
    font-size: 0.8em;
    text-align: center;
    width: 100%;

    @media (max-height: 1024px) {
      font-size: 0.75em;
    }
  }

  span.see-more {
    font-weight: 600;
    font-family: "GlacialIndifferenceRegular";
    letter-spacing: 0.1em;
  }

  .full-width {
    width: 100%;
  }

  div.wrapper-news {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-family: "Cinzel";
    padding: 50px 15px;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    @media (max-height: 1024px) {
      padding: 0 15px;

      .mt-5 {
        margin-top: 1rem !important;
      }
    }
  }

  div.box-news {
    img {
      height: 100px;
      width: 100%;
      object-fit: center;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
      flex-direction: column;
    }
    padding: 10px;
    background-color: #fffdf5;
    text-align: center;
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
    margin: 20px 0;
    &:hover {
      background-color: #e6e6e6;
      box-shadow: 0px 15px 20px -6px rgb(0 0 0 / 60%);
    }

    @media (max-height: 1024px) {
      margin: 10px 0;
    }
  }

  @media (max-width: 1024px) {
    height: 100%;
  }
`;
