import styled from "styled-components";

import { HOME_BG } from "../../assets/images";

export const Container = styled.div`
  @import url("https://fontlibrary.org//face/glacial-indifference");

  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);

  input,
  button,
  a {
    font-family: "GlacialIndifferenceRegular";
    letter-spacing: 0.1em;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  background-color: #fffdf5;
  background-image: url(${HOME_BG});
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: right bottom;
  height: 100%;

  .container {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1100px) {
    background-size: 80%;
  }

  @media (max-width: 580px) {
    background-size: 100%;
    .btn {
      padding: 0.5rem 1.6rem;
      font-size: 0.64rem;
    }
  }

  .green-btn {
    font-weight: 600;
    background-color: #10401b !important;
    color: #fff !important;
  }

  .beige-btn {
    font-weight: 600;
    background-color: #e8debd !important;
    color: #10401b !important;
  }
`;
