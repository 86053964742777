import styled from "styled-components";

import { LINHA_VERDE } from "../../assets/images";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  height: 100vh;
  overflow-y: scroll;

  @media (max-height: 1024px) {
    .mt-5,
    .mt-4 {
      margin-top: 1rem !important;
    }

    .md-form {
      margin-top: 0.5rem;
    }

    height: 100%;
  }

  h1 {
    color: #10401b;

    @media (max-height: 1024px) {
      font-size: 2rem;
    }
  }

  .card {
    font-family: "GlacialIndifferenceRegular";
    background-color: #fffdf5;
  }

  .green-line-left {
    background-image: url(${LINHA_VERDE});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center right;
    height: 100%;
  }

  .green-line-right {
    background-image: url(${LINHA_VERDE});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center left;
    height: 100%;
    transform: scaleX(-1);
  }

  .text-insta {
    color: #000;
    font-weight: 600;
  }

  /* @media (max-width: 1350px) {
    display: none;
  } */
`;
