import React from "react";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Routes from "./routes";
import history from "./routes/history";
import GlobalStyle from "./styles/global-style";
import "react-toastify/dist/ReactToastify.css";

const App = () => (
  <Router history={history}>
    <GlobalStyle />
    <Routes />
    <ToastContainer autoClose={3000} />
  </Router>
);
export default App;
