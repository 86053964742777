import styled from "styled-components";

import { LINHA_BRANCA } from "../../assets/images";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #86bb73;
  height: 100vh;
  align-items: center;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);

  h1 {
    color: #fff;
    text-shadow: 2px 2px rgb(0 0 0 / 30%);

    @media (max-height: 1024px) {
      font-size: 2rem;
    }
  }

  .card-title {
    font-family: "Cinzel";
    color: #fff;
    text-shadow: 2px 2px rgb(0 0 0 / 30%);
    font-weight: 600;
    text-transform: uppercase;
  }

  .card-text {
    font-family: "GlacialIndifferenceRegular";
    color: #fff;
    letter-spacing: 0.1em;
    font-size: 16px;
  }

  .container {
    height: 100%;
    background-color: #86bb73;
    padding-top: 25px;
    /* border-left: 2px solid #10401b;
    border-right: 2px solid #10401b; */
  }

  .white-line-left {
    background-image: url(${LINHA_BRANCA});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center right;
    height: 100%;
  }

  .white-line-right {
    background-image: url(${LINHA_BRANCA});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center left;
    height: 100%;
    transform: scaleX(-1);
  }

  span {
    font-family: "GlacialIndifferenceRegular";
    color: #fff;
    letter-spacing: 0.1em;
    font-size: 1.3rem;

    text-align: center;
    text-shadow: 2px 1px rgb(0 0 0 / 30%);

    @media (max-height: 1024px) {
      font-size: 1em;
    }

    &.text-name {
      font-size: 1.1rem;
      font-weight: 600;
    }

    &.text-title {
      font-size: 1rem;
    }
  }

  .full-width {
    width: 100%;
  }

  div.wrapper-photos {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 5vh;

    @media (max-height: 1024px) {
      padding: 2vh;
    }

    .partner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .image {
      border-radius: 100%;
      display: flex;
      flex: ${(props) => (props.showBio ? "0 0 100px" : "0 0 150px")};
      height: ${(props) => (props.showBio ? "100px" : "150px")};
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: ${(props) => (props.showBio ? "100px" : "150px")};
    }

    .image img {
      height: 100%;
    }

    .img-overlay {
      border-radius: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.25s;
      z-index: 1;
      background-color: rgba(46, 204, 113, 0.4);
      background: linear-gradient(
        65deg,
        rgba(46, 204, 113, 0.4),
        rgba(243, 156, 18, 0.4)
      );
      color: #fafafa;
      font-family: "GlacialIndifferenceRegular";
      font-weight: 600;
      font-size: 14px;
      height: 150px;
      width: 150px;
    }

    .img-overlay:hover {
      opacity: 1;
      cursor: pointer;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-link.active {
      background-color: #86bb73 !important;
    }
  }

  @media (max-width: 1350px) {
    display: none;
  }
`;
