// import React, { useState } from "react";
// import { NavHashLink as Link } from "react-router-hash-link";

// import {
//   MDBNavbar,
//   MDBNavbarBrand,
//   MDBNavbarNav,
//   MDBNavItem,
//   MDBCollapse,
//   MDBContainer,
//   MDBHamburgerToggler,
// } from "mdbreact";

// import { LOGO } from "../../assets/images";

// import { Container } from "./styles";

// export default function NavbarMob() {
//   const scrollWithOffset = (el, offset) => {
//     window.scroll({
//       top: el.offsetTop - offset,
//       left: 0,
//       behavior: "smooth",
//     });

//     toggle();
//   };

//   const [collapse, setCollapse] = useState(false);

//   const toggle = () => {
//     setCollapse(!collapse);
//   };

//   return (
//     <Container>
//       <MDBNavbar color="white" dark fixed="top">
//         <MDBContainer>
//           <MDBNavbarBrand>
//             <a href="https://arimaegalicio.com.br">
//               <img
//                 src={LOGO}
//                 alt="ArimaGalicio logo"
//                 width="200"
//                 style={{
//                   maxWidth: "100%",
//                   verticalAlign: "middle",
//                   display: "inline-block",
//                 }}
//               />
//             </a>
//           </MDBNavbarBrand>
//           <MDBHamburgerToggler color="#000" id="hamburger1" onClick={toggle} />
//           <MDBCollapse isOpen={collapse} navbar>
//             <MDBNavbarNav left>
//               <MDBNavItem>
//                 <Link
//                   to="/#home"
//                   smooth={true}
//                   scroll={(el) => scrollWithOffset(el, 75)}
//                   exact
//                 >
//                   HOME
//                 </Link>
//               </MDBNavItem>
//               <MDBNavItem>
//                 <Link
//                   to="/#aboutusm"
//                   smooth={true}
//                   duration={500}
//                   scroll={(el) => scrollWithOffset(el, 75)}
//                   exact
//                 >
//                   QUEM SOMOS
//                 </Link>
//               </MDBNavItem>
//               <MDBNavItem>
//                 <Link
//                   to="/#team"
//                   smooth={true}
//                   duration={500}
//                   scroll={(el) => scrollWithOffset(el, 75)}
//                   exact
//                 >
//                   NOSSA EQUIPE
//                 </Link>
//               </MDBNavItem>
//               <MDBNavItem>
//                 <Link
//                   to="/#field"
//                   smooth={true}
//                   duration={500}
//                   scroll={(el) => scrollWithOffset(el, 75)}
//                   exact
//                 >
//                   ATUAÇÃO
//                 </Link>
//               </MDBNavItem>
//               <MDBNavItem>
//                 <Link
//                   to="/#news"
//                   smooth={true}
//                   duration={500}
//                   scroll={(el) => scrollWithOffset(el, 75)}
//                   exact
//                 >
//                   ENTREVISTAS E PALESTRAS
//                 </Link>
//               </MDBNavItem>
//               <MDBNavItem>
//                 <Link
//                   to="/#contact"
//                   smooth={true}
//                   duration={500}
//                   scroll={(el) => scrollWithOffset(el, 75)}
//                   exact
//                 >
//                   CONTATO
//                 </Link>
//               </MDBNavItem>
//             </MDBNavbarNav>
//           </MDBCollapse>
//         </MDBContainer>
//       </MDBNavbar>
//     </Container>
//   );
// }

import React, { useState } from "react";
import { NavHashLink as Link } from "react-router-hash-link";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBIcon,
  MDBBtn,
} from "mdbreact";

import { LOGO } from "../../assets/images";

import { Container } from "./styles";

export default function NavbarMob() {
  const scrollWithOffset = (el, offset) => {
    window.scroll({
      top: el.offsetTop - offset,
      left: 0,
      behavior: "smooth",
    });

    toggle();
  };

  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <Container>
      <MDBNavbar color="white" dark fixed="top">
        <MDBNavbarBrand>
          <a href="https://arimaegalicio.com.br">
            <img
              src={LOGO}
              alt="ArimaGalicio logo"
              width="200"
              style={{
                maxWidth: "100%",
                verticalAlign: "middle",
                display: "inline-block",
              }}
            />
          </a>
        </MDBNavbarBrand>
        <MDBBtn flat size="sm" onClick={toggle}>
          <MDBIcon icon="bars" size="2x" />
        </MDBBtn>
        <MDBCollapse isOpen={collapse} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <Link
                to="/#home"
                smooth={true}
                scroll={(el) => scrollWithOffset(el, 75)}
                exact
              >
                HOME
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link
                to="/#aboutusm"
                smooth={true}
                duration={500}
                scroll={(el) => scrollWithOffset(el, 75)}
                exact
              >
                QUEM SOMOS
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link
                to="/#teamm"
                smooth={true}
                duration={500}
                scroll={(el) => scrollWithOffset(el, 75)}
                exact
              >
                NOSSA EQUIPE
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link
                to="/#field"
                smooth={true}
                duration={500}
                scroll={(el) => scrollWithOffset(el, 75)}
                exact
              >
                ATUAÇÃO
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link
                to="/#news"
                smooth={true}
                duration={500}
                scroll={(el) => scrollWithOffset(el, 75)}
                exact
              >
                ENTREVISTAS E PALESTRAS
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link
                to="/#contact"
                smooth={true}
                duration={500}
                scroll={(el) => scrollWithOffset(el, 75)}
                exact
              >
                CONTATO
              </Link>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    </Container>
  );
}
