import React, { useState } from "react";
import { NavHashLink as Link } from "react-router-hash-link";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
} from "mdbreact";

import { LOGO } from "../../assets/images";

import { Container } from "./styles";

export default function Navbar() {
  const scrollWithOffset = (el, offset) => {
    window.scroll({
      top: el.offsetTop - offset,
      left: 0,
      behavior: "smooth",
    });
  };

  const [collapsed, setCollapsed] = useState(false);

  const handleTogglerClick = () => {
    setCollapsed(!collapsed);
  };

  const overlay = (
    <div
      id="sidenav-overlay"
      style={{ backgroundColor: "transparent" }}
      onClick={handleTogglerClick}
    />
  );

  return (
    <Container>
      <MDBNavbar color="white" dark expand="md" fixed="top" scrolling>
        <MDBContainer>
          <MDBNavbarBrand>
            <a href="https://arimaegalicio.com.br">
              <img
                src={LOGO}
                alt="ArimaGalicio logo"
                width="250"
                style={{
                  maxWidth: "100%",
                  verticalAlign: "middle",
                  display: "inline-block",
                }}
              />
            </a>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={handleTogglerClick} />
          <MDBCollapse isOpen={collapsed} navbar>
            <Link
              to="/#home"
              smooth={true}
              scroll={(el) => scrollWithOffset(el, 75)}
              exact
            >
              HOME
            </Link>
            <span className="divisor">|</span>
            <Link
              to="/#aboutus"
              smooth={true}
              duration={500}
              scroll={(el) => scrollWithOffset(el, 75)}
              exact
            >
              QUEM SOMOS
            </Link>
            <span className="divisor">|</span>
            <Link
              to="/#team"
              smooth={true}
              duration={500}
              scroll={(el) => scrollWithOffset(el, 75)}
              exact
            >
              NOSSA EQUIPE
            </Link>
            <span className="divisor">|</span>
            <Link
              to="/#field"
              smooth={true}
              duration={500}
              scroll={(el) => scrollWithOffset(el, 75)}
              exact
            >
              ATUAÇÃO
            </Link>
            <span className="divisor">|</span>
            <Link
              to="/#news"
              smooth={true}
              duration={500}
              scroll={(el) => scrollWithOffset(el, 75)}
              exact
            >
              ENTREVISTAS E PALESTRAS
            </Link>
            <span className="divisor">|</span>
            <Link
              to="/#contact"
              smooth={true}
              duration={500}
              scroll={(el) => scrollWithOffset(el, 75)}
              exact
            >
              CONTATO
            </Link>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      {collapsed && overlay}
    </Container>
  );
}
