import React from "react";

import { MDBRow, MDBCol, MDBContainer } from "mdbreact";

import { Container } from "./styles";

export default function News() {
  return (
    <Container id="news">
      <MDBContainer>
        <MDBRow className="mt-3">
          <MDBCol lg="4" md="4" sm="4">
            <div className="white-line-left" />
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4" className="text-center">
            <h1>ENTREVISTAS E PALESTRAS</h1>
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4">
            <div className="white-line-right" />
          </MDBCol>
        </MDBRow>
        <div className="wrapper-news">
          <MDBRow className="mt-2 mb-2">
            <MDBCol lg="4" md="4" sm="12">
              <div className="box-news">
                <a
                  href="https://soucannabis.ong.br/voce-sabe-o-que-sao-os-habeas-corpus-de-cultivo-de-cannabis/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="news-title">
                    Você sabe o que são os Habeas Corpus de Cultivo de Cannabis?
                  </span>
                  <span className="news-desc">
                    Advogadas da Rede Reforma esclarecem dúvidas sobre o
                    processo... <span className="see-more">ver mais</span>
                  </span>
                </a>
              </div>
            </MDBCol>
            <MDBCol lg="4" md="4" sm="12">
              <div className="box-news">
                <a
                  href="https://cannalize.com.br/dificuldades-atuais-de-quem-precisa-plantar-o-proprio-remedio-legalmente/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="news-title">
                    Dificuldades atuais de quem precisa plantar o próprio
                    remédio legalmente
                  </span>
                  <span className="news-desc">
                    A vida de pacientes que precisam da cannabis medicinal não é
                    fácil... <span className="see-more">ver mais</span>
                  </span>
                </a>
              </div>
            </MDBCol>
            <MDBCol lg="4" md="4" sm="12">
              <div className="box-news">
                <a
                  href="https://g1.globo.com/sp/sao-paulo/noticia/2021/02/09/justica-autoriza-21-familias-de-sp-ligadas-a-associacao-a-plantarem-maconha-sem-risco-de-prisao.ghtml"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="news-title">
                    Justiça autoriza 21 famílias de SP ligadas à associação a
                    plantarem maconha sem risco de prisão
                  </span>
                  <span className="news-desc">
                    É o primeiro habeas corpus coletivo do país para plantação
                    domiciliar... <span className="see-more">ver mais</span>
                  </span>
                </a>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-2 mb-2">
            <MDBCol lg="4" md="4" sm="12">
              <div className="box-news">
                <a
                  href="https://www.smokebuddies.com.br/tres-mulheres-que-enaltecem-a-luta-antiproibicionista-no-brasil/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="news-title">
                    Três mulheres que enaltecem a luta antiproibicionista no
                    Brasil
                  </span>
                  <span className="news-desc">
                    Donas de narrativas poderosas, argumentos contundentes e
                    bagagens impressionantes, jovens advogadas que se dedicam à
                    defesa... <span className="see-more">ver mais</span>
                  </span>
                </a>
              </div>
            </MDBCol>
            <MDBCol lg="4" md="4" sm="12">
              <div className="box-news">
                <a
                  href="https://www.smokebuddies.com.br/stj-e-habeas-corpus-para-cultivo/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="news-title">
                    STJ e Habeas Corpus para cultivo: quando uma mentira dita
                    mil vezes se torna jurisprudência
                  </span>
                  <span className="news-desc">
                    Os Habeas Corpus preventivos para cultivo de Cannabis com
                    finalidade terapêutica já são uma realidade para o
                    tratamento das mais diversas enfermidades...{" "}
                    <span className="see-more">ver mais</span>
                  </span>
                </a>
              </div>
            </MDBCol>
            <MDBCol lg="4" md="4" sm="12">
              <div className="box-news">
                <a
                  href={
                    "https://www.youtube.com/watch?v=smkFxTQs2Rg&t=2s&ab_channel=GrupoPrerrogativas"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="news-title">
                    VÍDEO - Legalização da maconha: jurisprudência e autocultivo
                    por pacientes
                  </span>
                  <span className="news-desc">
                    Debate com Cristiano Maronna, Emílio Figueiredo, Cecília
                    Galício, Gabriella Arima e Sidarta Ribeiro.
                  </span>
                </a>
              </div>
            </MDBCol>
            <MDBCol lg="4" md="4" sm="12">
              <div className="box-news">
                <a
                  href={
                    "https://www.conjur.com.br/2021-mai-29/larissa-itri-qual-papel-mulheres-advocacia-criminal"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="news-title">
                    Qual o papel das mulheres na advocacia criminal?
                  </span>
                  <span className="news-desc">
                    Durante a audiência, quatro corréus, dois advogados e duas
                    advogadas presenciavam as farpas trocadas...{" "}
                    <span className="see-more">ver mais</span>
                  </span>
                </a>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-2 mb-2">
            <MDBCol lg="4" md="4" sm="4"></MDBCol>
            <MDBCol lg="4" md="4" sm="4"></MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
    </Container>
  );
}
