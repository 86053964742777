import styled from "styled-components";

import { LINHA_VERDE } from "../../assets/images";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);

  h1 {
    color: #10401b;

    @media (max-height: 1024px) {
      font-size: 2rem;
    }
  }

  .container {
    background-color: #fff;
    height: 100%;
  }

  .green-line-left {
    background-image: url(${LINHA_VERDE});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center right;
    height: 100%;
  }

  .green-line-right {
    background-image: url(${LINHA_VERDE});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center left;
    height: 100%;
    transform: scaleX(-1);
  }

  h3 {
    color: #773e34;
    font-family: "Cinzel";
    font-weight: 600;
    padding: 10px;
  }

  p {
    font-family: "GlacialIndifferenceRegular";
    color: #000;
    letter-spacing: 0.1em;
    font-size: 1.1rem;

    @media (max-height: 1024px) {
      font-size: 0.9em;
    }
  }

  @media (max-width: 1350px) {
    display: none;
  }
`;
