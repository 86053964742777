import React, { useEffect, useState, useCallback } from "react";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import emailjs from "emailjs-com";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  MDBCard,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBIcon,
  MDBInput,
  MDBBtn
} from "mdbreact";

import { Container } from "./styles";

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha();
    setCaptchaValue(token);
  }, [executeRecaptcha]);
  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  const sendEmail = values => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        { ...values, "g-captcha-resṕonse": captchaValue },
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        result => {
          setLoading(false);
        },
        error => {
          setLoading(false);
        }
      );

    toast.success("Email enviado. Agradecemos seu contato!");

    setLoading(false);
  };

  const { handleSubmit, values, handleChange, resetForm } = useFormik({
    initialValues: {
      from_name: "",
      from_email: "",
      from_phone: "",
      subject: "",
      message: ""
    },
    onSubmit: values => {
      setLoading(true);
      if (
        !values.from_name ||
        !values.from_email ||
        !values.from_phone ||
        !values.subject ||
        !values.message
      ) {
        toast.error("Informe todos os campos");
        setLoading(false);
      } else {
        if (captchaValue) {
          sendEmail(values);
        }
        resetForm();
      }
    }
  });

  return (
    <Container id="contact">
      <MDBContainer className="mt-5">
        <MDBRow className="mb-3 mt-5">
          <MDBCol lg="4" md="4" sm="4">
            <div className="green-line-left" />
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4" className="text-center">
            <h1>CONTATO</h1>
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4">
            <div className="green-line-right" />
          </MDBCol>
        </MDBRow>
        <MDBCard>
          <MDBRow>
            <MDBCol lg="8">
              <MDBCardBody className="form">
                <h5 className="mt-4 text-center">
                  <MDBIcon icon="envelope" className="pr-2" />
                  Para entrar em contato conosco, preencha os dados abaixo:
                </h5>
                <form onSubmit={handleSubmit}>
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          type="text"
                          id="form-contact-name"
                          name="from_name"
                          value={values.from_name}
                          label="Seu nome"
                          onChange={handleChange}
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          type="text"
                          id="form-contact-email"
                          label="Seu email"
                          name="from_email"
                          value={values.from_email}
                          onChange={handleChange}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          type="text"
                          id="form-contact-phone"
                          label="Seu telefone"
                          name="from_phone"
                          value={values.from_phone}
                          onChange={handleChange}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <div className="md-form mb-0">
                        <MDBInput
                          type="text"
                          id="form-subject"
                          label="Assunto"
                          name="subject"
                          value={values.subject}
                          onChange={handleChange}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <div className="md-form mb-0">
                        <div onClick={handleReCaptchaVerify}></div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="md-form mb-0 text-center">
                        <MDBInput
                          type="textarea"
                          id="form-contact-message"
                          label="Mensagem"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          rows="3"
                        />
                        <MDBBtn color="blue" type="submit" disabled={loading}>
                          Enviar
                          <MDBIcon icon="paper-plane" className="ml-3" />
                        </MDBBtn>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </form>
              </MDBCardBody>
            </MDBCol>
            <MDBCol lg="4" sm="12">
              <MDBCardBody className="contact text-center h-100 d-flex flex-column align-items-center justify-content-center">
                <ul className="text-lg-left list-unstyled">
                  <li>
                    <p>
                      <MDBIcon icon="map-marker-alt" className="pr-2" />
                      São Paulo - SP, Brasil
                    </p>
                  </li>
                  <li>
                    <p>
                      <MDBIcon fab icon="whatsapp" className="pr-2" />
                      <a
                        href="https://wa.me/5511940340985"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +55 11 94034-0985
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <MDBIcon icon="envelope" className="pr-2" />
                      <a
                        href="mailto:contato@arimaegalicio.com.br"
                        target="_blank"
                        rel="noreferrer"
                      >
                        contato@arimaegalicio.com.br
                      </a>
                    </p>
                  </li>
                </ul>
                <hr className="hr-light my-4" />
                <ul className="list-inline text-center list-unstyled">
                  <li className="d-flex flex-column">
                    <span className="text-insta">
                      Acompanhe nosso conteúdo no Instagram
                    </span>
                    <a
                      href="https://www.instagram.com/arimaegalicioadv/"
                      className="p-2 fa-lg w-ic"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MDBIcon fab icon="instagram" size="2x" />
                    </a>
                  </li>
                </ul>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
    </Container>
  );
}
