import React from "react";

import { Container, Title, Description } from "../styles/page-not-found-style";

export default function PageNotFound() {
  return (
    <Container>
      <Title>404 - Página não encontrada</Title>
      <Description>Esta Página está indisponível no momento.</Description>
    </Container>
  );
}
