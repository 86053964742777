import styled from "styled-components";

export const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");

  font-family: "Cinzel";

  display: flex;
  width: 100%;

  li.nav-item {
    padding: 5px;
  }

  a {
    color: #000 !important;
    font-weight: 300;

    @media (max-width: 992px) {
      font-size: 60%;
    }

    &:hover {
      font-weight: 800;
    }
  }

  @media (min-width: 1350px) {
    display: none;
  }
`;
