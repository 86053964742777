import styled from "styled-components";

export const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
  @import url("https://fontlibrary.org//face/glacial-indifference");

  font-family: "Cinzel";

  display: flex;
  width: 100%;
  flex-direction: column;

  .navbar-collapse {
    justify-content: center;
    align-items: center;
    font-size: 16px;

    a {
      color: #000 !important;
      font-weight: 300;

      @media (max-width: 992px) {
        font-size: 60%;
      }

      &:hover {
        font-weight: 800;
      }
    }
  }

  .navbar.scrolling-navbar.top-nav-collapse {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .divisor {
    padding: 0 10px;
    font-weight: 600;

    @media (max-width: 992px) {
      font-size: 60%;
    }
  }

  @media (max-width: 1350px) {
    display: none;
  }
`;
