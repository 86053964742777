import React, { useState, useEffect } from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import { MDBMask, MDBRow, MDBCol, MDBContainer, MDBAnimation } from "mdbreact";

import { Container, Content } from "./styles";

export default function Home() {
  const scrollWithOffset = (el, offset) => {
    window.scroll({
      top: el.offsetTop - offset,
      left: 0,
      behavior: "smooth"
    });
  };

  const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return {
      width
    };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  return (
    <Container id="home">
      <Content>
        <MDBMask className="white-text gradient" />
        <MDBContainer>
          <MDBRow>
            <MDBCol
              md="8"
              sm="12"
              className="text-center text-md-left mt-xl-5 mb-5"
            >
              <MDBAnimation type="fadeInLeft" delay=".3s">
                <h1 className="h1-responsive mt-sm-5">
                  ESCRITÓRIO ANTIPROIBICIONISTA
                </h1>
                <h6 className="mb-4">
                  ESPECIALIZADO EM ADVOCACIA CRIMINAL, COM FOCO EM POLÍTICA DE
                  DROGAS E DIREITOS HUMANOS.
                </h6>
                <Link
                  to={width < 1350 ? "#aboutusm" : "#aboutus"}
                  className="btn Ripple-parent btn-lg btn-default green-btn"
                  smooth={true}
                  scroll={el => scrollWithOffset(el, 75)}
                  exact
                >
                  SAIBA MAIS
                </Link>

                <Link
                  to="#contact"
                  className="btn Ripple-parent btn-lg btn-default beige-btn"
                  smooth={true}
                  scroll={el => scrollWithOffset(el, 75)}
                  exact
                >
                  CONTATO
                </Link>
              </MDBAnimation>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Content>
    </Container>
  );
}
