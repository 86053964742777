import styled from "styled-components";

import { LINHA_VERDE } from "../../assets/images";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);

  h1 {
    color: #10401b;

    @media (max-height: 1024px) {
      font-size: 2rem;
    }
  }

  .container {
    background-color: #fff;
    height: 100%;
  }

  .green-line-left {
    background-image: url(${LINHA_VERDE});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center right;
    height: 100%;
  }

  .green-line-right {
    background-image: url(${LINHA_VERDE});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center left;
    height: 100%;
    transform: scaleX(-1);
  }

  div.wrapper-field-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #773e34;
    font-family: "Cinzel";
    font-weight: 600;
    padding: 10px;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    .col-lg-4,
    .col-md-4,
    .col-sm-12 {
      height: 70%;
    }

    div.box {
      display: flex;
      flex-direction: column;
      padding: 10px;
      background-color: #e8debd;
      height: 60%;
      text-align: center;
      border-radius: 10px;

      @media (max-height: 1024px) {
        height: 70%;
        margin: 25px;
      }

      div.icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 0;

        @media (max-height: 1024px) {
          padding: 15px 0;
        }
      }
    }
  }

  span {
    font-family: "GlacialIndifferenceRegular";
    color: #773e34;
    letter-spacing: 0.1em;
    font-size: 1.3rem;

    @media (max-width: 1200px) {
      font-size: 1.1em;
    }
  }

  @media (max-width: 800px) {
    height: 100%;
  }
`;
