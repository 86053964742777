import React from "react";

import CookieConsent from "react-cookie-consent";

import Home from "../../components/Home";
import AboutUs from "../../components/AboutUs";
import AboutUsMob from "../../components/AboutUsMob";
import Team from "../../components/Team";
import TeamMob from "../../components/TeamMob";
import Field from "../../components/Field";
import News from "../../components/News";
import Contact from "../../components/Contact";

export default function Main() {
  return (
    <div>
      <Home />
      <AboutUs />
      <AboutUsMob />
      <Team />
      <TeamMob />
      <Field />
      <News />
      <Contact />
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="cookie-consent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "blue", fontSize: "13px" }}
        expires={150}
      >
        <span style={{ fontSize: "10px", fontFamily: "Arial" }}>
          Embora não utilizemos cookies próprios, nosso site integra
          funcionalidades de terceiros que acabarão enviando cookies para seu
          dispositivo. Ao prosseguir navegando no site, estes cookies coletarão
          dados pessoais indiretos.
        </span>
      </CookieConsent>
    </div>
  );
}
