import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }

  body {    
    @import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");


    font-family: "Cinzel";
    font-size: 14px !important;
    color: #333333 !important;
    background: #fff !important;
    text-rendering: optimizeLegibility !important;
  }

 

  button {
    cursor: pointer !important;
  }

  .nav-link {
    color: #000 !important;
  }
`;
