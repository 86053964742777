import styled from "styled-components";

import { LINHA_BRANCA } from "../../assets/images";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #86bb73;
  height: 100%;
  align-items: center;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);

  h1 {
    color: #fff;
    text-shadow: 2px 2px rgb(0 0 0 / 30%);

    @media (max-height: 1024px) {
      font-size: 2rem;
    }
  }

  .card-title {
    font-family: "Cinzel";
    color: #fff;
    text-shadow: 2px 2px rgb(0 0 0 / 30%);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
  }

  .card-text {
    font-family: "GlacialIndifferenceRegular";
    color: #fff;
    letter-spacing: 0.1em;
    font-size: 16px;
  }

  .container {
    height: 100%;
    background-color: #86bb73;
    padding-top: 25px;
  }

  .white-line-left {
    background-image: url(${LINHA_BRANCA});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center right;
    height: 100%;
  }

  .white-line-right {
    background-image: url(${LINHA_BRANCA});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center left;
    height: 100%;
    transform: scaleX(-1);
  }

  span {
    font-family: "GlacialIndifferenceRegular";
    color: #fff;
    letter-spacing: 0.1em;
    font-size: 1.3rem;

    text-align: center;
    text-shadow: 2px 1px rgb(0 0 0 / 30%);

    @media (max-height: 1024px) {
      font-size: 1em;
    }

    &.text-name {
      font-size: 1.1rem;
      font-weight: 600;
    }

    &.text-title {
      font-size: 1rem;
    }
  }

  .full-width {
    width: 100%;
  }

  div.wrapper-photos {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (max-height: 1024px) {
      padding: 2vh;
    }

    .partner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
    }

    .image {
      border-radius: 100%;
      display: flex;
      flex: "0 0 150px";
      height: "150px";
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: "150px";
    }

    .image img {
      height: 100%;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-link.active {
      background-color: #86bb73 !important;
    }
  }

  @media (min-width: 1350px) {
    display: none;
  }
`;
