export const LOGO =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631029621/arima-galicio/LOGO_ARIMAEGALICIO_pojn6q.png";

export const HOME_BG =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1637072262/arima-galicio/placanova_icqxyr.png";

export const LINHA_BRANCA =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631029638/arima-galicio/LINHA_DESENHADA_BRANCO_vkvz4h.png";

export const LINHA_VERDE =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631054588/arima-galicio/LINHA_DESENHADA_SM_zk0jjr.png";

export const GUIRLANDA =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631029639/arima-galicio/GUIRLANDA_ARIMAEGALICIO_jwetmd.png";

export const CECI =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631999221/arima-galicio/CECI_tiqyuc.jpg";

export const LARI =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631999221/arima-galicio/LARI_gz4dub.jpg";

export const GABI =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631999221/arima-galicio/GABI_nggesw.jpg";

export const PIONEIRISMO_ICO =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631054588/arima-galicio/PIONEIRISMO_ICONE_SM_nbipay.png";

export const VALORES_ICO =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631054588/arima-galicio/VALORES_ICONE_SM_h9yf10.png";

export const MISSAO_ICO =
  "https://res.cloudinary.com/vl-cloudinary/image/upload/v1631054588/arima-galicio/MISSAO_ICONE_SM_zeilg2.png";
