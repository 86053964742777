import React from "react";

import { MDBRow, MDBCol, MDBContainer, MDBIcon } from "mdbreact";

import { Container } from "./styles";

export default function Field() {
  return (
    <Container id="field">
      <MDBContainer>
        <MDBRow className="mb-1 mt-5">
          <MDBCol lg="4" md="4" sm="4">
            <div className="green-line-left" />
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4" className="text-center">
            <h1>ATUAÇÃO</h1>
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4">
            <div className="green-line-right" />
          </MDBCol>
        </MDBRow>
        <div className="wrapper-field-text">
          <MDBCol lg="4" md="4" sm="12">
            <div className="box">
              <div className="icon-wrapper">
                <MDBIcon icon="handshake" size="3x" />
              </div>

              <span>
                Assessoria consultiva e preventiva para pessoas físicas e
                jurídicas
              </span>
            </div>
          </MDBCol>
          <MDBCol lg="4" md="4" sm="12">
            <div className="box">
              <div className="icon-wrapper">
                <MDBIcon icon="balance-scale" size="3x" />
              </div>
              <span>
                Atuação em inquéritos policiais, procedimentos investigatórios,
                defesas e representações em ações penais
              </span>
            </div>
          </MDBCol>
          <MDBCol lg="4" md="4" sm="12">
            <div className="box">
              <div className="icon-wrapper">
                <MDBIcon icon="microphone-alt" size="3x" />
              </div>
              <span>Palestras e Cursos</span>
            </div>
          </MDBCol>
        </div>
      </MDBContainer>
    </Container>
  );
}
