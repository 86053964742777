import React from "react";

import { MDBRow, MDBCol, MDBContainer } from "mdbreact";

import { MISSAO_ICO, PIONEIRISMO_ICO, VALORES_ICO } from "../../assets/images";

import { Container } from "./styles";

export default function AboutUsMob() {
  return (
    <Container id="aboutusm">
      <MDBContainer>
        <MDBRow className="mb-3 mt-5">
          <MDBCol lg="4" md="4" sm="4">
            <div className="green-line-left" />
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4" className="text-center">
            <h1>QUEM SOMOS</h1>
          </MDBCol>
          <MDBCol lg="4" md="4" sm="4">
            <div className="green-line-right" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <img src={MISSAO_ICO} width="130" alt="" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <h3>MISSÃO</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <p>
              A NOSSA MISSÃO É TRANSFORMAR A REALIDADE DA JUSTIÇA CRIMINAL
              BRASILEIRA ATRAVÉS DA INCIDẼNCIA ANTIPROIBICIONISTA EM CASOS
              PARADIGMÁTICOS QUE ENVOLVEM A LEI DE DROGAS. SEDIADO EM SÃO
              PAULO/SP, O ESCRITÓRIO É COMPOSTO POR ADVOGADAS ATIVISTAS QUE SE
              PROPÕE A FACILITAR O ACESSO À JUSTIÇA ATRAVÉS DE UMA ADVOCACIA
              POPULAR E DISRUPTIVA.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <img src={VALORES_ICO} width="130" alt="" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <h3>VALORES</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <p>
              ÉTICA E TRANSPARÊNCIA PARA DEFENDER, SOBRETUDO, O RESPEITO À
              DIGNIDADE E OS DIREITOS HUMANOS, A VALORIZAÇÃO DA SAÚDE E A
              LIBERDADE DE ESCOLHAS INDIVIDUAIS. NOSSOS VALORES TAMBÉM INTEGRAM
              A LUTA ANTIRRACISTA, ANTIMANICOMIAL, FEMINISTA, LGBTQIA+ E
              ABOLICIONISTA.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <img src={PIONEIRISMO_ICO} width="130" alt="" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <h3>PIONEIRISMO</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <p>
              ALÉM DA LEI DE DROGAS, AUXILIAMOS PACIENTES QUE NECESSITAM DA
              PLANTA DENOMINADA CANNABIS COMO FERRAMENTA TERAPÊUTICA, SENDO
              PIONEIRAS NESTE CAMPO, COM COMPROVADA EXPERIÊNCIA NA SOLUÇÃO DE
              PROBLEMAS JURÍDICOS DE ALTA COMPLEXIDADE. TAMBÉM ATENDEMOS PESSOAS
              JURÍDICAS QUE BUSCAM ORIENTAÇÕES RELACIONADAS À ATUAÇÃO COM
              SUBSTÂNCIAS PROSCRITAS.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
