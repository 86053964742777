import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PageNotFound from "../pages/PageNotFound";
import Main from "../pages/Main";
import Navbar from "../components/Navbar";
import NavbarMob from "../components/NavbarMob";

const Routes = () => (
  <Router>
    <Navbar />
    <NavbarMob />
    <Switch>
      <Route exact path="/" component={Main} />
      <Route path="*" exact component={PageNotFound} />
    </Switch>
  </Router>
);

export default Routes;
